// @flow

import loadable from '@loadable/component';

import { Layout } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import FullMenu from '_components/FullMenu';
import Seo from '_components/Seo';

import Footer from './Footer';
import styles from './index.module.less';

const Navbar = loadable(() => import('_components/Navbar'));

const { Header, Content } = Layout;

type Props = {
  children: React$Node,
  floatingNavbar?: boolean,
  inverseFooter?: boolean,
  footerTrianglePosition?: 'left' | 'right',
  navbarTemplate?: 'default' | 'sign-in' | 'checkout' | 'profile',
};

const AppLayout = ({
  children,
  floatingNavbar = true,
  inverseFooter = false,
  footerTrianglePosition,
  navbarTemplate = 'default',
}: Props): React$Node => {
  const queryClient = new QueryClient();

  const [fullMenuVisible, setFullMenuVisible] = useState(false);

  const toggleFullMenu = useCallback(() => setFullMenuVisible((v) => !v), []);

  return (
    <QueryClientProvider client={queryClient}>
      <Seo />
      <Layout className={classNames(styles.Layout, 'rf-layout')}>
        <Header className={styles.Header}>
          <Navbar
            fullMenuVisible={fullMenuVisible}
            toggleFullMenu={toggleFullMenu}
            floating={floatingNavbar}
            template={navbarTemplate}
          />
          {fullMenuVisible && <FullMenu toggleFullMenu={toggleFullMenu} />}
        </Header>

        <Content className={styles.Content}>{children}</Content>
        <Footer type={inverseFooter ? 'inverse' : 'default'} trianglePosition={footerTrianglePosition} />
      </Layout>
    </QueryClientProvider>
  );
};

export default AppLayout;
