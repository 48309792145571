// @flow
import './Footer.less';

import { Divider } from 'antd';
import classNames from 'classnames';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import AppStoreBadges from '_components/AppStoreBadges';
import FooterSectionName from '_components/FooterSectionName';
import LogoImage from '_images/logo.png';

type Props = {
  type?: 'default' | 'inverse',
  trianglePosition?: 'left' | 'right',
};

const Footer = ({ type = 'default', trianglePosition }: Props): React$Node => {
  const { t } = useTranslation();

  return (
    <footer className={classNames(`footer-${type}`, { 'footer-triangled': !!trianglePosition })}>
      {trianglePosition && <div className={`triangle-top-${trianglePosition}`} />}
      <div className="footer-container">
        <div className="content">
          <div className="media-links">
            <Link to="/">
              <img className="logo" src={LogoImage} alt="Ritual FIT Logo" />
            </Link>
            <AppStoreBadges />
          </div>

          <div className="sitemap">
            <div className="sitemap-links">
              <FooterSectionName>{t('Footer_SectionQuickLinks')}</FooterSectionName>
              <Link to="/">{t('Common_Home')}</Link>
              <Link to="/checkout">{t('Common_FreeTrial')}</Link>
              <Link to="/faq">{t('Common_FAQ')}</Link>
              <Link to="/contact">{t('Common_ContactUs')}</Link>
              <a href="https://ritualgym.com" alt="Ritual Gym" target="_blank" rel="noreferrer">
                Ritual Gym
              </a>
            </div>

            <div className="sitemap-links">
              <FooterSectionName>{t('Footer_SectionConnect')}</FooterSectionName>
              <a href="http://facebook.com/ritualFIT" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
              <a href="https://www.instagram.com/ritual.fit/" target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
              <a href="https://open.spotify.com/user/ritualgym" target="_blank" rel="noopener noreferrer">
                Spotify
              </a>
            </div>

            <div className="sitemap-links">
              <FooterSectionName>{t('Footer_SectionLegal')}</FooterSectionName>
              <Link to="/terms">{t('Common_TermsOfUse')}</Link>
              <Link to="/privacy">{t('Common_PrivacyPolicy')}</Link>
            </div>
          </div>
        </div>
        <Divider className="divider" />
        <div className="copyright">Copyright © 2023 Slated Labs Pte Ltd.</div>
      </div>
    </footer>
  );
};

export default Footer;
