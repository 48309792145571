// @flow
import { useLocation } from '@reach/router';

import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteUrl: url
        image
        description
        author
      }
    }
  }
`;

const Seo = (): React$Node => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const seo = useMemo(
    () => ({
      ...site.siteMetadata,
      url: `${site.siteMetadata.siteUrl}${pathname}`,
    }),
    [pathname, site.siteMetadata]
  );

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:type" content="website" />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default Seo;
