// @flow

import './index.less';

import React from 'react';

import FacebookIcon from '_images/social-facebook-white.png';
import InstagramIcon from '_images/social-instagram-white.png';
import SpotifyIcon from '_images/social-spotify-white.png';

const SocialMediaIconLinks = (): React$Node => (
  <div className="social-icons">
    <a className="social-icon" href="http://facebook.com/ritualFIT" target="_blank" rel="noopener noreferrer">
      <img src={FacebookIcon} alt="Facebook Icon" rel="noopener noreferrer" />
    </a>
    <a className="social-icon" href="https://www.instagram.com/ritual.fit/" target="_blank" rel="noopener noreferrer">
      <img src={InstagramIcon} alt="Instagram Icon" />
    </a>
    <a className="social-icon" href="https://open.spotify.com/user/ritualgym" target="_blank" rel="noopener noreferrer">
      <img src={SpotifyIcon} alt="Spotify Icon" />
    </a>
  </div>
);

export default SocialMediaIconLinks;
