// @flow
import React from 'react';

import styles from './index.module.less';

type Props = {
  children: string,
};

const FooterSectionName = ({ children }: Props): React$Node => (
  <div className={styles.SectionName}>
    <span className={styles.SurroundingText}>:::</span>
    {` ${children} `}
    <span className={styles.SurroundingText}>:::</span>
  </div>
);

export default FooterSectionName;
