// @flow
import './index.less';

import classNames from 'classnames';
import React from 'react';

import AppStoreBadge from '_images/badges/badge-appstore.png';
import PlayStoreBadge from '_images/badges/badge-playstore.png';

type Props = {
  size?: 'md' | 'lg',
  vertical?: boolean,
  className?: string,
};

const AppStoreBadges = ({ size = 'md', vertical = false, className }: Props): React$Node => (
  <div
    className={classNames(
      'app-store-badges',
      `app-store-badges-${size}`,
      {
        'app-store-badges-vertical': vertical,
      },
      className
    )}
  >
    <a href="https://apps.apple.com/app/apple-store/id1507358909" target="_blank" rel="noopener noreferrer">
      <img src={AppStoreBadge} alt="App Store Badge" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.ritualgym.anywhere"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={PlayStoreBadge} alt="Play Store Badge" />
    </a>
  </div>
);

export default AppStoreBadges;
