// @flow
import './index.less';

import { Col, Divider, Row } from 'antd';
import { Link } from 'gatsby-plugin-react-i18next';
import React, { useCallback } from 'react';

import Breakpoint from '_components/Breakpoint';
import LegalLinks from '_components/LegalLinks';
import SocialMediaIconLinks from '_components/SocialMediaIconLinks';
import { useAuth, useAuthState } from '_contexts/auth';

type Props = {
  toggleFullMenu: () => void,
};

const FullMenu = ({ toggleFullMenu }: Props): React$Node => {
  const { currentUser } = useAuthState();
  const { signOut } = useAuth();

  const handleSignOut = useCallback(
    () =>
      (async () => {
        await signOut();
        toggleFullMenu();
      })(),
    [signOut, toggleFullMenu]
  );

  return (
    <div className="fullmenu">
      <div className="navmenu">
        <Link className="nav-button" to="/" onClick={toggleFullMenu}>
          Home
        </Link>
        <Link className="nav-button" to="/faq" onClick={toggleFullMenu}>
          FAQ
        </Link>
        <Link className="nav-button" to="/contact" onClick={toggleFullMenu}>
          Contact
        </Link>

        {currentUser ? (
          <a className="nav-button" onClick={handleSignOut} onKeyPress={handleSignOut} role="button" tabIndex="0">
            Sign Out
          </a>
        ) : (
          <Link className="nav-button" to="/login">
            Sign In
          </Link>
        )}
      </div>

      <Divider className="divider" />

      <Breakpoint min="md">
        <Row>
          <Col span={12}>
            <SocialMediaIconLinks />
          </Col>
          <Col span={12}>
            <LegalLinks />
          </Col>
        </Row>
      </Breakpoint>

      <Breakpoint max="md">
        <Row>
          <Col span={24}>
            <SocialMediaIconLinks />
          </Col>
          <Col span={24}>
            <LegalLinks />
          </Col>
        </Row>
      </Breakpoint>
    </div>
  );
};

export default FullMenu;
