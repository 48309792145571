// @flow
import React, { useMemo } from 'react';
import MediaQuery from 'react-responsive';

/*
  NOTE: Use <Breakpoint> only for hiding/showing components/jsx.
  Breakpoint takes 2 props, min and max.
  Specifying both results in a ranged css width media query, xxx and yyy.
  Specifying one prop results in a single css width media query.
 */

// Breakpoints follow antd's default breakpoints, so that it matches up when using antd Row/Col components
const breakpoints = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 480,
};

type Size = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

type Props = {
  children?: React$Node,
  min?: Size,
  max?: Size,
};

const Breakpoint = ({ children, min, max }: Props): React$Node => {
  const query = useMemo(() => {
    const queries = [];
    if (min) {
      queries.push(`(min-width: ${breakpoints[min]}px)`);
    }
    if (max) {
      queries.push(`(max-width: ${breakpoints[max] - 1}px)`);
    }

    return queries.join(' and ');
  }, [min, max]);

  return <MediaQuery query={query}>{children}</MediaQuery>;
};

export default Breakpoint;
