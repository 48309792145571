// @flow
import './index.less';

import { Divider } from 'antd';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';

const LegalLinks = (): React$Node => (
  <div className="legal-links">
    <Link to="/privacy">Privacy Policy</Link>
    <Divider type="vertical" />
    <Link to="/terms">Terms of Use</Link>
  </div>
);

export default LegalLinks;
